<template>
  <div class="portal-container">
    <div class="portal-header">
      <div class="portal-title">
        <img src="/images/vars_logo.png" alt="VARS Technology" />
        <h1>Portal</h1>
      </div>
      <!-- Logout Icon Button -->
      <button class="logout-button" @click="logout">
        <i class="material-icons" title="Logout">logout</i>
      </button>
    </div>

    <div class="portal-card-container">
      <!-- Customer Portal -->
      <PortalCard
        title="Customer"
        :url="
          'https://portal.varsanpr.com/login?token=' +
          $store.state.auth.user.token
        "
      />

      <!-- NBCS Portal -->
      <PortalCard
        v-if="$can('ncs-view')"
        title="NBCS"
        :url="
          'https://nbcs.varsanpr.com/login?token=' +
          $store.state.auth.user.token
        "
      />

      <!-- DCBL Portal -->
      <PortalCard
        v-if="$can('dcbl-view')"
        title="DCBL"
        :url="
          'https://dcbl.varsanpr.com/login?token=' +
          $store.state.auth.user.token
        "
      />

      <!-- Admin Portal -->
      <PortalCard
        v-if="$can('portals-admin')"
        title="Admin"
        :url="
          'https://admin.varsanpr.com/login?token=' +
          $store.state.auth.user.token
        "
      />

      <!-- Claims Portal -->
      <PortalCard
        v-if="$can('claims-process')"
        title="Claims"
        :url="
          'https://claims.varsanpr.com/login?token=' +
          $store.state.auth.user.token
        "
      />
    </div>
  </div>
</template>

<script>
import PortalCard from "../components/PortalCard.vue";

export default {
  name: "VARS Portal",
  components: {
    PortalCard,
  },
  mounted() {
    if (
      !this.$can("ncs-view") &&
      !this.$can("portals-admin") &&
      !this.$can("claims-process")
    ) {
      this.customerPortal();
    }
  },
  methods: {
    logout() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
  },
};
</script>

<style scoped>
@keyframes grow {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.portal-container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 40px 20px;
}

.portal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  width: 100%;
  position: relative;
}

.portal-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
}

img {
  height: 50px;
}

h1 {
  font-size: 36px;
  font-weight: 400;
  margin: 0;
  text-transform: uppercase;
}

.logout-button {
  background-color: #1f1f2b;
  color: #9a9cab;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  position: absolute;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  right: 20px;
  transition: background-color 0.2s ease, box-shadow 0.2s ease;
}

.logout-button i {
  font-size: 28px;
}

.logout-button:hover {
  background-color: #2b2b3d;
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.15);
}

.portal-card-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 40px;
  max-width: 1200px;
  justify-content: center;
}

.portal-card {
  animation: grow 0.5s ease-out;
  transform-origin: center;
  opacity: 0;
  animation-fill-mode: forwards;
}
</style>
